// Kontrastbild Startseite (Karte)

.card-contrast {
  display: none;
}

.card-normal {
  display: block;
}

svg {
  width: 100%;
  height: auto;
}