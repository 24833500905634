#footer {
  .btn-primary a{
      color: $white;
  }

  .ce_image.update{
    @include media-breakpoint-down(sm) {
      margin-bottom: 3rem;
    }
  }
}