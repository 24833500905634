.mod_filter form .year input[type=radio]:checked+label {
  background-color: $primary;
}

// antabbar Filter Jahre
.mod_filter form .year input[type=radio] {
  @include visually-hidden-focusable;
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  //   padding: 0 !important;
  //   margin: -1px!important;
  //   overflow: hidden !important;
  //   clip: rect(0, 0, 0, 0) !important;
  //   white-space: nowrap !important;
  //   border: 0 !important;
}

// .mod_filter form .year input[type=radio]:focus+label {
//   outline: 3px solid rgba(255, 255, 255, 1) !important;
//   box-shadow: 0px 0px 0px 6px rgba(0, 0, 0, 1) !important;
//   border: 3px solid $black;
// }

// .mod_filter form .year input[type=radio]:not(:focus):not(:active) {
//   position: absolute;
//   width: 1px;
//   height: 1px;
//   clip: rect(0 0 0 0);
//   clip-path: inset(50%);
//   overflow: hidden;
//   white-space: nowrap;
// }

.mod_filter form .year label:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.mod_filter form .year label {
  position: relative;
  color: #fff;
  background-color: #bbb;
  margin-right: 2px;
  margin-bottom: 2px;
  border: none;
  font-weight: $font-weight-bold;
}

.mod_filter form .year label {
  padding: 12px 35px;
  transition: background-color .3s,padding .3s,color .3s;
  border-radius: 0!important;
}

.mod_filter form .year label:hover {
  background-color: $secondary;
  color: #fff;
}

body > #wrapper #main [class^=ce_]:not([class*=ce_headline]):not([class*=ce_beg_headline]):not([class*=ce_colset]):not(img):not([class*=ce_tiny-slider-content-separator]):not([class*=ce_tiny-slider-separator]):not([class*=ce_text]) {
  margin-bottom: 0rem;
}

body > #wrapper #main {
  padding-top: 0rem;
}

.form-select {
  border: 1px solid #009FE3;
}

.year-filter .mod_filter form
{
  display: flex;
  flex-direction: row;
  & > div {
    width: 25%;
    @include media-breakpoint-down(sm) {
      width: 40%;
    }
  }

  //div:first-child {
  //  width: 25%;
  //}

  label {
    color: $primary;
    font-weight: $font-weight-bold;
    margin-bottom: 0.5rem;
  }
  select {
    width: 100%;
    text-align-last: center;
    font-weight: $font-weight-bold;
    font-size: 1.125rem;
    line-height: 1.5;
    color: $primary!important;
    border: 1px solid #009FE3;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    background: $white;
    background-color: $white;
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    border-radius: 0.3rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none;
  }
  button[type=submit] {
    width: 100%;
    margin-top: 2.3rem;
    margin-left: 1rem;
    padding: 0.2rem;
    text-align: center;
    height: 37px;
    background-color: $white;
    color: $primary;
    border: 2px solid $primary;
    font-weight: $font-weight-bold;
    &:hover {
      background-color: $primary !important;
      color: $white !important;
      font-weight: $font-weight-bold;
    }
  }
}

// Registrierung Regionalkonferenzen

.mod_registration.conference {
  margin-top: 2rem;
  form {
    @include media-breakpoint-up(md) {
      .fields {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .form-group {
            flex: 50%;
          }
      }
    }
    @include media-breakpoint-down(sm) {
      .fields {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        .form-group {
            flex: 100%;
          }
      }
    }
  }
}