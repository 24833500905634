body {

  &.high-contrast {

  .ce_text.bg-blue-light, .ce_text.bg-light {
    border: 2px solid $primary;
  }

  .card-contrast {
    display: block;
  }

  .card-normal {
    display: none;
  }

  }
}
