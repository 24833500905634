#bahnland_navigation_top_mobile.mm-menu_popup {
  .mm-panels {
    .level_1,
    .level_2,
    .level_3 {
      a, .mm-listitem_selected {
        border: 1px solid rgba(0, 95, 163, 0.5);
      }
    }
  }
}

.mm-listitem {
  &:after {
    content: none;
  }
}

